import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Buscar","outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[(_vm.getUserRole === 0 || _vm.getUserRole === 1)?_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openAssignCreditsModal()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Transferir créditos")])],1):_vm._e()],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"page":_vm.current_page,"items":_vm.movementListTable,"options":_vm.options,"server-items-length":_vm.totalMovementListTable,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.changingCreditsPage($event)},"update:items-per-page":function($event){return _vm.changingCreditsItemsPerPage($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No hay "),_c('strong',[_vm._v("Créditos")]),_vm._v(" disponibles para esta búsqueda ")])],1)]},proxy:true},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.$refs.assign_credits_modal.openModal(item.id)}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$refs.delete_company_modal.open(item)}}},[_c(VIcon,{staticClass:"error--text"},[_vm._v(" "+_vm._s(item.enabled? _vm.icons.mdiDeleteOutline : _vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),(_vm.getUserRole === 0)?_c('assign-credits-for-admin-modal',{ref:"assign_credits_for_admin_modal",on:{"reload-movement-list":function($event){return _vm.getCreditsMovements()}}}):_vm._e(),(_vm.getUserRole === 1)?_c('assign-credits-for-company-admin-modal',{ref:"assign_credits_for_company_admin_modal",on:{"reload-movement-list":function($event){return _vm.getCreditsMovements()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }