import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"700","scrollable":"","persistent":"","minHeight":"1200"},model:{value:(_vm.showingMovementModal),callback:function ($$v) {_vm.showingMovementModal=$$v},expression:"showingMovementModal"}},[_c(VForm,{ref:"movementForm",on:{"submit":function($event){$event.preventDefault();return _vm.createMovement.apply(null, arguments)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Transferir créditos a una empresa ")]),_c(VCardText,[_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VAutocomplete,{attrs:{"name":"company_form_channel","items":_vm.getAllCompaniesList,"search-input":_vm.searchCompany,"hide-no-data":!_vm.searchCompany,"filter":_vm.filter,"label":"Empresa destino","persistent-hint":"","hint":"Puedes buscar una empresa escribiendo el nombre","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"label":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),(_vm.company)?_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"credits-banner d-flex align-center flex-wrap"},[_c(VAvatar,{staticClass:"banner-avatar",attrs:{"rounded":"","size":"43"}},[_c(VImg,{attrs:{"contain":"","width":"23","height":"20","src":require("@/assets/images/pages/wallet.png")}})],1),_c('div',{staticClass:"font-weight-medium ms-3"},[_c('p',{staticClass:"text--primary mb-0"},[_vm._v(" "+_vm._s(_vm.company.name)+" ")]),_c('span',{staticClass:"text-xs primary--text"},[_vm._v("Créditos actuales")])]),_c(VSpacer),_c('div',{staticClass:"d-flex align-center mt-2"},[_c('sup',[_c(VIcon,{attrs:{"size":"24"}},[_vm._v(_vm._s(_vm.icons.mdiAlphaCCircle))])],1),_c('sup',[_c('h1',{staticClass:"text-2xl"},[_vm._v(_vm._s(_vm.currentCompanyCredits))])])])],1)]):_vm._e(),_c(VCol,{attrs:{"cols":"6","md":"6"}},[_c(VSelect,{attrs:{"name":"action","items":['Transferir', 'Recuperar'],"label":"Seleccionar acción","required":"","hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.action),callback:function ($$v) {_vm.action=$$v},expression:"action"}})],1),_c(VCol,{attrs:{"cols":"6","md":"6"}},[_c(VTextField,{ref:"amount",attrs:{"rules":[function (v) { return !!v || 'Campo requerido'; }],"outlined":"","type":"number","dense":"","min":"0","autocomplete":"off","hide-details":"","label":"Cantidad de créditos","error":_vm.errorAmount},on:{"change":function($event){_vm.errorAmount = false}},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"color":"primary","type":"submit"}},[_vm._v(" REALIZAR TRANSFERENCIA ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }