<template>
  <v-card>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="searchQuery"
        @keyup="handleSearch"
        placeholder="Buscar"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
      >
      </v-text-field>

      <v-spacer></v-spacer>


      <div class="d-flex align-center flex-wrap">
        <v-btn
          color="primary"
          class="mb-4 me-3"
          v-if="getUserRole === 0 || getUserRole === 1"
          @click="openAssignCreditsModal()"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Transferir créditos</span>
        </v-btn>

      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedRows"
      :headers="tableColumns"
      :page="current_page"
      :items="movementListTable"
      :options.sync="options"
      :server-items-length="totalMovementListTable"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
      @update:page="changingCreditsPage($event)"
      @update:items-per-page="changingCreditsItemsPerPage($event)"
    >
      <!-- NO data -->
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            No hay
            <strong>Créditos</strong>
            disponibles para esta búsqueda
          </v-list-item-title>
        </v-list-item>
      </template>

      <!-- edit -->
      <template #[`item.edit`]="{item}">
        <v-btn
          icon
          color="primary"
          @click="$refs.assign_credits_modal.openModal(item.id)"
        >
          <v-icon>
            {{ icons.mdiSquareEditOutline }}
          </v-icon>
        </v-btn>
      </template>

      <!-- delete -->
      <template #[`item.delete`]="{item}">
        <v-btn icon @click="$refs.delete_company_modal.open(item)">
          <v-icon class="error--text">
            {{ item.enabled? icons.mdiDeleteOutline : icons.mdiDeleteOutline }}
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <assign-credits-for-admin-modal
      v-if="getUserRole === 0"
      ref="assign_credits_for_admin_modal"
      @reload-movement-list="getCreditsMovements()"
    />

    <assign-credits-for-company-admin-modal
      v-if="getUserRole === 1"
      ref="assign_credits_for_company_admin_modal"
      @reload-movement-list="getCreditsMovements()"
    />

  </v-card>
</template>

<script>
const debounce = require('debounce');

// eslint-disable-next-line object-curly-newline
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiSquareEditOutline,
} from '@mdi/js'
import {  ref } from '@vue/composition-api'
import useUsersList from '../Messages/useUsersList'

// sidebar
import AssignCreditsForAdminModal from './AssignCreditsForAdminModal.vue'
import AssignCreditsForCompanyAdminModal from './AssignCreditsForCompanyAdminModal.vue'

export default {
data: () => {
  return {
    current_page: 1,
    page_size: 10,

    enabled_toggle_confirm_modal: false,

  }
},
mounted() {
  if(this.getSelectedCompany) {
    this.loading = true
    this.getCreditsMovements()
  }


  // if (this.getSelectedCompany) this.getCompanies()
},
methods: {



  openAssignCreditsModal() {
    console.log(this.getUserRole === 0? 'Admin modal' : 'Company admin modal');
    this.getUserRole === 0
      ? this.$refs.assign_credits_for_admin_modal.openModal()
      : this.$refs.assign_credits_for_company_admin_modal.openModal()
  },
  setFilter() {
    this.current_page = 1
    this.getCreditsMovements()
  },
  handleSearch: debounce(function (e) {
    // console.log(this.searchQuery)
    this.current_page = 1
    this.getCreditsMovements()
  }, 400),

  changingCreditsPage(e) {
    this.current_page = e
    this.getCreditsMovements()
  },

  changingCreditsItemsPerPage(e) {
    this.page_size = e
    this.getCreditsMovements()
  },

  async getCreditsMovements() {
    this.loading = true

    const data = {
      page_size: this.page_size,
      page: this.current_page,
      search: this.searchQuery,
    }

    try {
      const response = await this.$api.getCreditsMovements(data)

      this.movementListTable = response.results
      this.totalMovementListTable = response.count
    } catch (e) {
      console.log(e)
    } finally { this.loading = false }
  },
},
computed: {
  dateOptions() { return {weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", hour12:"false", minute: "numeric", second: "numeric"}},
  getSelectedCompany() { return this.$store.getters['session/getSelectedCompany']() },
  getUserRole() { return this.$store.getters['session/getUserRole']() },
},
watch: {
  getSelectedCompany (newValue) {
    if (newValue) {
      this.getCreditsMovements()
    }
  }
},
components: {
  AssignCreditsForAdminModal,
  AssignCreditsForCompanyAdminModal
},
setup() {


  const {
    searchQuery,
    selectedRows,

  } = useUsersList()


  // New configurations

  const totalMovementListTable = ref(0)

  const tableColumns = [
    { text: 'FECHA', value: 'created_at', align: 'center', sortable: false },
    { text: 'CANTIDAD', value: 'amount', align: 'center', sortable: false },
    { text: 'DESDE', value: 'source', align: 'center', sortable: false },
    { text: 'HACIA', value: 'to', align: 'center', sortable: false },
    { text: 'AUTOR', value: 'movement_by', align: 'center', sortable: false },
  ]

  const loading = ref(false)

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const movementListTable = ref([])

  const resolveChipColorVariant = chip => {
    if (chip === 'red') return 'error'
    if (chip === 'green') return 'success'
    if (chip === 'violet') return 'primary'

    return 'primary'
  }


  // New configurations


  return {
    movementListTable,
    tableColumns,
    searchQuery,
    totalMovementListTable,
    loading,
    options,
    selectedRows,
    resolveChipColorVariant,

    // icons
    icons: {
      mdiDeleteOutline,
      mdiPlus,
      mdiCheckCircleOutline,
      mdiCloseCircleOutline,
      mdiSquareEditOutline,
    },
  }
},
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
