
<template>
  <v-dialog v-model="showingMovementModal" width="700" scrollable persistent minHeight="1200">
    <v-form ref="movementForm" @submit.prevent="createMovement">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Transferir créditos a una empresa
        </v-card-title>

        <v-card-text>

          <v-row class="mt-2">

            <v-col cols="12" md="12">
              <v-autocomplete
                name="company_form_channel" 
                v-model="company" 
                :items="getAllCompaniesList" 
                :search-input="searchCompany"
                :hide-no-data="!searchCompany"
                :filter="filter"
                label="Empresa destino"
                persistent-hint
                hint="Puedes buscar una empresa escribiendo el nombre"
                outlined 
                dense
              >
            
                <template v-slot:selection="{ item }"> <!-- v-slot:selection="{ attrs, item, parent, selected }" -->
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip label>
                    {{ item.name }}
                  </v-chip>
                </template>

              </v-autocomplete>
            </v-col>


            <v-col v-if="company" cols="12" md="12">
              <div class="credits-banner d-flex align-center flex-wrap">
              <v-avatar
                rounded
                class="banner-avatar"
                size="43"
              >
                <v-img
                  contain
                  width="23"
                  height="20"
                  src="@/assets/images/pages/wallet.png"
                ></v-img>
              </v-avatar>

              <div class="font-weight-medium ms-3">
                <p class="text--primary mb-0">
                  {{ company.name }}
                </p>
                <span class="text-xs primary--text">Créditos actuales</span>
              </div>

              <v-spacer></v-spacer>

              <div class="d-flex align-center mt-2">
                <sup>
                  <v-icon size="24">{{ icons.mdiAlphaCCircle }}</v-icon>
                </sup>
                <sup><h1 class="text-2xl">{{ currentCompanyCredits }}</h1></sup>
                <!-- <sub>/Year</sub> -->
              </div>
            </div>
            </v-col>



            <v-col cols="6" md="6">
              <v-select 
                name="action" 
                v-model="action" 
                :items="['Transferir', 'Recuperar']" 
                label="Seleccionar acción"
                required 
                hide-details="auto" 
                outlined 
                dense
              ></v-select>
            </v-col>
            
            <v-col cols="6" md="6">
              <v-text-field 
                ref="amount" 
                v-model="amount"
                :rules="[v => !!v || 'Campo requerido']" 
                outlined 
                type="number"
                dense
                min="0"
                autocomplete="off" 
                hide-details 
                label="Cantidad de créditos"
                :error="errorAmount"
                @change="errorAmount = false"
              ></v-text-field>
            </v-col>

          </v-row>
            
        </v-card-text>
          
          
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">
            CANCELAR
          </v-btn>
          <v-btn color="primary" type="submit">
            REALIZAR TRANSFERENCIA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
  
  <script>
  // eslint-disable-next-line object-curly-newline
  import { 
    mdiAlphaCCircle,
    mdiClockTimeFourOutline,
    mdiClose
  } from '@mdi/js'
  import { ref } from '@vue/composition-api'
  
  
  export default {
    data: () => {
      return {
        company: null,
        amount: null,
        action: null,
        errorAmount: false,
        currentCompanyCredits: null,

        // movement_form: {
        //   name: '',
        //   open: '',
        // },
        selectedMovement: [],
        // allChannelList: [],
        // allUsersList: [],
        searchCompany: null,

      }
    },
    mounted() {
      // this.getChannelList()
      // this.getUsersForCreateMovement()
    },
    methods: {
      filter (item, queryText, itemText) {
        const hasValue = val => val != null ? val : ''

        const text = hasValue(item.name)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },

      // filterUser (item, queryText, itemText) {
      //   const hasValue = val => val != null ? val : ''
      //   const text = hasValue(item.user)
      //   const query = hasValue(queryText)

      //   return text.toString()
      //     .toLowerCase()
      //     .indexOf(query.toString().toLowerCase()) > -1
      // },


      openModal(id_to_edit) {
        id_to_edit 
          ? this.setItemToEdit(id_to_edit)
          : this.showingMovementModal = true
      },

      close() {
        this.resetForm()
        this.showingMovementModal = false
      },

      validateAmount() {
        const isValid = this.action === 'Transferir' || Number(this.amount) <= Number(this.currentCompanyCredits)
        this.errorAmount = !isValid
        console.log(isValid);
        return isValid
      },


      async createMovement() {
        if (this.$refs.movementForm.validate() && this.validateAmount()) {
          
          const form = {
            credits: Number(this.amount),
            add: this.action === 'Transferir'
          }

          try {
            const response = await this.$api.addCompanyCredits(this.company.id, form)

            console.log(response)
            this.$emit('reload-movement-list')
            this.close()
          } catch (error) {
            console.log(error)
          } 

        }
        
      },

      async getCompanyCredits(company) {
        const credits = await this.$api.getCompanyCredits(company.id)
        this.currentCompanyCredits = credits
      },

      resetForm() {
        this.$refs.movementForm.reset()
      }
    },
    computed: {
      getUserRole() { return this.$store.getters['session/getUserRole']() },
      getAllCompaniesList() { return this.$store.getters['session/getAllCompaniesList']() },
    },
    watch: {
      company(newValue) {
        if (!newValue) return
        this.getCompanyCredits(newValue)
      }
    },
    setup() {
      const name = ref('')
      const open = ref('')
      const showingMovementModal = ref(false)
  
      return {
        showingMovementModal,
        name,
        open,
        icons: {
          mdiClose,
          mdiClockTimeFourOutline,
          mdiAlphaCCircle,
        },
      }
    },
  }
  </script>
  <style scoped>
    .credits-banner {
      background-color: rgba(145,85,253,.12);
      padding: 0.563rem 0.688rem;
      border-radius: 4px;
    }
  </style>