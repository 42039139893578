
<template>
  <v-dialog 
    v-model="showingMovementModal" 
    width="700" 
    scrollable 
    persistent 
  >
    <v-form ref="movementForm" @submit.prevent="createMovement">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Transferir créditos a una empresa
        </v-card-title>

        <v-card-text>

          <v-row class="mt-2">

            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="company" 
                :items="getAllCompaniesList" 
                :search-input="searchCompany"
                :hide-no-data="!searchCompany"
                :filter="filter"
                :error="errorNoCompany"
                @change="errorNoCompany = false"
                label="Seleccionar empresa"
                persistent-hint
                hint="Puedes buscar una empresa escribiendo el nombre"
                outlined 
                dense
              >
            
                <template v-slot:selection="{ item }"> <!-- v-slot:selection="{ attrs, item, parent, selected }" -->
                  <!-- <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :input-value="selected"
                    label
                  >
                  </v-chip> -->
                  <span class="pr-2">
                    {{ item.name }}
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip label>
                    {{ item.name }}
                  </v-chip>
                </template>

              </v-autocomplete>
            </v-col>

            <v-col v-if="company" cols="12" md="12">
              <div class="credits-banner d-flex align-center flex-wrap">
                <v-avatar
                  rounded
                  class="banner-avatar"
                  size="43"
                >
                  <v-img
                    contain
                    width="23"
                    height="20"
                    src="@/assets/images/pages/wallet.png"
                  ></v-img>
                </v-avatar>

                <div class="font-weight-medium ms-3">
                  <p class="text--primary mb-0">
                    {{ company.name }}
                  </p>
                  <span class="text-xs primary--text">Créditos actuales de la empresa</span>
                </div>

                <v-spacer></v-spacer>

                <div class="d-flex align-center mt-2">
                  <sup>
                    <v-icon size="24">{{ icons.mdiAlphaCCircle }}</v-icon>
                  </sup>
                  <sup><h1 class="text-2xl">{{ selectedCompanyCredits }}</h1></sup>
                </div>
              </div>
            </v-col>


            <v-col cols="12" md="12">
              <v-autocomplete
                v-model="worker_id" 
                :disabled="!company"
                :items="allUsersList" 
                :search-input="searchUser"
                :hide-no-data="!searchUser"
                :filter="filterUser"
                :error="errorNoUser"
                @change="errorNoUser = false"
                required
                item-text="user"
                item-value="id"
                label="Usuario destinatario"
                persistent-hint
                hint="Puedes buscar un usuario escribiendo el nombre"
                outlined 
                dense
              >
            
                <template v-slot:selection="{ item }"> <!-- v-slot:selection="{ attrs, item, parent, selected }" -->
                  <!-- <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :input-value="selected"
                    label
                  >
                  </v-chip> -->
                  <span class="pr-2">
                    {{ item.user }}
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <v-chip label>
                    {{ item.user }}
                  </v-chip>
                </template>

              </v-autocomplete>
            </v-col>


            <v-col v-if="worker_id" cols="12" md="12">
              <div class="credits-banner d-flex align-center flex-wrap">
                <v-avatar
                  rounded
                  class="banner-avatar"
                  size="43"
                >
                  <v-img
                    contain
                    width="23"
                    height="20"
                    src="@/assets/images/pages/user.png"
                  ></v-img>
                </v-avatar>

                <div class="font-weight-medium ms-3">
                  <p class="text--primary mb-0">
                    {{ getUserName(worker_id) }}
                  </p>
                  <span class="text-xs primary--text">Créditos actuales del usuario</span>
                </div>

                <v-spacer></v-spacer>

                <div class="d-flex align-center mt-2">
                  <sup>
                    <v-icon size="24">{{ icons.mdiAlphaCCircle }}</v-icon>
                  </sup>
                  <sup><h1 class="text-2xl">{{ selectedWorkerCredits }}</h1></sup>
                </div>

              </div>
            </v-col>



            <v-col cols="6" md="6">
              <v-select 
                name="action" 
                v-model="action" 
                :items="['Transferir', 'Recuperar']" 
                label="Seleccionar acción"
                required 
                :error="errorAction"
                @change="errorAction = false"
                hide-details="auto" 
                outlined 
                dense
              ></v-select>
            </v-col>
            
            <v-col cols="6" md="6">
              <v-text-field 
                ref="amount" 
                v-model="amount"
                :rules="[v => !!v || 'Campo requerido']" 
                outlined 
                type="number"
                dense
                min="0"
                autocomplete="off" 
                hide-details 
                label="Cantidad de créditos"
                :error="errorAmount"
                @change="errorAmount = false"
              ></v-text-field>
            </v-col>

          </v-row>
            
        </v-card-text>
          
          
        <v-divider class="mt-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">
            CANCELAR
          </v-btn>
          <v-btn color="primary" type="submit">
            REALIZAR TRANSFERENCIA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
  
  <script>
  // eslint-disable-next-line object-curly-newline
  import { 
    mdiAlphaCCircle,
    mdiClockTimeFourOutline,
    mdiClose
  } from '@mdi/js'
  import { ref } from '@vue/composition-api'
  
  
  export default {
    data: () => {
      return {
        company: null,
        worker_id: null,
        amount: null,
        action: null,
        errorAmount: false,
        errorNoCompany: false,
        errorNoUser: false,
        errorAction: false,
        selectedCompanyCredits: null,
        selectedWorkerCredits: null,

        // movement_form: {
        //   name: '',
        //   open: '',
        // },
        selectedMovement: [],
        // allChannelList: [],
        allUsersList: [],
        searchCompany: null,
        searchUser: null,

      }
    },
    mounted() {
      // this.getChannelList()
      // this.getUsersForCreateMovement()
    },
    methods: {
      filter (item, queryText, itemText) {
        const hasValue = val => val != null ? val : ''

        const text = hasValue(item.name)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },

      filterUser (item, queryText, itemText) {
        const hasValue = val => val != null ? val : ''

        const text = hasValue(item.user)
        const query = hasValue(queryText)

        return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      },

      // filterUser (item, queryText, itemText) {
      //   const hasValue = val => val != null ? val : ''
      //   const text = hasValue(item.user)
      //   const query = hasValue(queryText)

      //   return text.toString()
      //     .toLowerCase()
      //     .indexOf(query.toString().toLowerCase()) > -1
      // },

      getUserName(id) {
        const userFound = this.allUsersList.find(el => el.id === id)
        return userFound.user
      },

      openModal(id_to_edit) {
        id_to_edit 
          ? this.setItemToEdit(id_to_edit)
          : this.showingMovementModal = true
      },

      close() {
        this.resetForm()
        this.showingMovementModal = false
      },

      manualValidate() {
        let isValid = true

        if (isValid) {
          isValid = !!this.company
          this.errorNoCompany = !isValid
        }

        if (isValid) {
          isValid = !!this.worker_id
          this.errorNoUser = !isValid
        }

        if (isValid) {
          isValid = !!this.action
          this.errorAction = !isValid
        }

        if (isValid && this.action === 'Transferir') {
          isValid = Number(this.amount) <= Number(this.selectedCompanyCredits)
          this.errorAmount = !isValid
        } else if (isValid &&this.action === 'Rescatar') {
          isValid = Number(this.amount) <= Number(this.selectedWorkerCredits)
          this.errorAmount = !isValid
        }

        // const isValid = this.action === 'Transferir' || Number(this.amount) <= Number(this.selectedCompanyCredits)
        console.log(isValid);
        return isValid
      },


      async createMovement() {
        if (this.$refs.movementForm.validate() && this.manualValidate()) {
          
          const form = {
            worker_id: this.worker_id,
            credits: Number(this.amount),
            add: this.action === 'Transferir'
          }

          try {
            const response = await this.$api.addCreditsToWorker(this.company.id, form)

            console.log(response)
            this.$emit('reload-movement-list')
            this.close()
          } catch (error) {
            console.log(error)
          } 

        }
        
      },


      async getUsersList(company) {
        const users = await this.$api.getUsersForCreditsMovements(company.id)
        this.allUsersList = users
      },
      
      
      async getCompanyCredits(company) {
        const credits = await this.$api.getCompanyCredits(company.id)
        this.selectedCompanyCredits = credits
      },

      async getCreditsForWorker(worker_id) {
        const credits = await this.$api.getCreditsForWorker(this.company.id, worker_id)
        this.selectedWorkerCredits = credits
      },

      resetForm() {
        this.$refs.movementForm.reset()
      }
    },
    computed: {
      getUserRole() { return this.$store.getters['session/getUserRole']() },
      getAllCompaniesList() { return this.$store.getters['session/getAllCompaniesList']() },
    },
    watch: {
      company(newValue) {
        if (!newValue) return
        console.log("watch company")
        this.getUsersList(newValue)
        this.getCompanyCredits(newValue)
      },
      worker_id(newValue) {
        if (!newValue) return
        console.log("watch worker_id")
        this.getCreditsForWorker(newValue)
      }
    },
    setup() {
      const name = ref('')
      const open = ref('')
      const showingMovementModal = ref(false)
  
      return {
        showingMovementModal,
        name,
        open,
        icons: {
          mdiClose,
          mdiClockTimeFourOutline,
          mdiAlphaCCircle,
        },
      }
    },
  }
  </script>
  <style scoped>
    .credits-banner {
      background-color: rgba(145,85,253,.12);
      padding: 0.563rem 0.688rem;
      border-radius: 4px;
    }
  </style>